





































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import NewUsersAttackHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/NewUsersAttackHandlerModule/NewUsersAttackHandlerModuleBuilder'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'

import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    TimeUnitInput,
    NumberInput,
    NestedContent,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
  },
})
export default class NewUsersAttackHandlerModuleView extends Mixins(ModuleBindings, InputSetups, TariffsTagsHelper) {
  @VModel() module!: NewUsersAttackHandlerModuleBuilder

  autoAttackModeInitValue: number | null = null

  get lowerThenMinOrHigherThenHour() {
    return this.autoAttackModeInitValue && ( this.autoAttackModeInitValue > 3600 || this.autoAttackModeInitValue < 60)
  }

  get hasAtLeastOneTag() {
    return this.getTagsByFieldKey('auto_attack_mode') === null ||
      this.getTagsByFieldKey('ban_inout_users') === null ||
      this.getTagsByFieldKey('ban_inout_users_time') === null
  }

  mounted() {
    this.autoAttackModeInitValue = this.module.model.params.auto_attack_mode_interval

    if (!this.hasAtLeastOneTag) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show(this.saveModule)
    }
  }
}
